<template>
  <div class="esw-related-links__list--popover"
    @click="closeModal($event)"
    @keyup.esc="$emit('closePopover', true)">
    <div class="esw-related-links__list--popover__content"
      aria-live="polite"
      aria-modal="true"
      role="dialog"
      ref="content">
      <Trap>
      <div class="esw-related-links__list--popover__header">
        <div class="esw-related-links__list--popover__heading">
          <p class="esw-related-links__list--popover__heading--text">
            Relaterad information
          </p>
        </div>
        <div class="esw-related-links__list--popover__info" v-if="pages.length">
         <span class="esw-related-links__list--popover__info--pre-text">Sida </span>
         <span class="esw-related-links__list--popover__info--text">
            ({{ currentPage }} av {{ pages.length }})
          </span>
        </div>
        <button class="esw-related-links__list--popover__close"
          @click="$emit('closePopover', true)">
          <span class="esw-related-links__list--popover__close--text">
            Stäng
          </span>
          <span class="esw-related-links__list--popover__close--icon rs_skip"
            aria-hidden="true">
          </span>
        </button>
      </div>
      <div class="esw-related-links__list--popover__list--wrapper">
        <ul class="esw-related-links__list--popover__list left">
          <li class="esw-related-links__list--popover__item"
            v-for="(link, index) in showItems.left"
            :key="index">
            <a class="esw-related-links__list--popover__item--link"
              :href="link.url">
              {{ link.title }}
            </a>
          </li>
        </ul>
        <ul class="esw-related-links__list--popover__list right">
          <li class="esw-related-links__list--popover__item"
            v-for="(link, index) in showItems.right"
            :key="index">
            <a class="esw-related-links__list--popover__item--link"
              :href="link.url">
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
      <div class="esw-related-links__list--popover__pagination"
        v-if="itemsPerPage < items.length">
        <button class="esw-related-links__list--popover__pagination--button prev"
          @click="showPrevPage()"
          :disabled="currentPage === pages[0]">
          <span class="show-for-sr">
            Visa föregående sida
          </span>
        </button>
        <div class="esw-related-links__list--popover__pagination--dots">
          <button class="esw-related-links__list--popover__pagination--button page-selector"
            v-for="page in pages"
            :key="page"
            @click="showPage(page)"
            :class="currentPage == page ? 'active' : ''"
            aria-current="location">
            <span class="show-for-sr">
              Visa sida {{ page }}
            </span>
          </button>
        </div>
        <button class="esw-related-links__list--popover__pagination--button next"
          @click="showNextPage()"
          :disabled="currentPage === pages[pages.length - 1]">
          <span class="show-for-sr">
            Visa nästa sida
          </span>
        </button>
      </div>
    </Trap>
    </div>
  </div>
</template>

<script>
import Trap from 'vue-focus-lock';

export default {
  name: "PopOver",
  props: {
    items: [],
  },

  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
    };
  },

  computed: {
    currentNumberOfItems() {
      return this.currentPage == 1 && this.itemsPerPage > this.items.length
        ? this.items.length
        : this.currentPage * this.itemsPerPage < this.items.length
        ? this.currentPage * this.itemsPerPage
        : this.items.length;
    },
    showItems() {
      let start = 0;
      let end = this.itemsPerPage;
      if (this.currentPage != 1) {
        start = (this.currentPage - 1) * this.itemsPerPage;
        end = this.currentPage * this.itemsPerPage;
      }
      let slicedItems = this.items.slice(start, end);
      let left = [];
      let right = [];
      for (let i = 0; i < slicedItems.length; i++) {
        if (i % 2 == 0) {
          left.push(slicedItems[i]);
        } else {
          right.push(slicedItems[i]);
        }
      }
      return {
        right: right,
        left: left,
      };
    },

    pages() {
      let x = this.items.length / this.itemsPerPage;
      let i = 1;
      let pageNumbers = [];
      while (i < x + 1) {
        pageNumbers.push(i);
        i++;
      }
      return pageNumbers;
    },
  },

  methods: {
    closeModal(event) {
      let target = event.target;
      let content = this.$refs.content;
      if (content && !content.contains(target)) {
        this.$emit("closePopover", true);
      }
    },

    showNextPage() {
      if (this.currentPage < this.pages.length) {
        this.currentPage++;
      }
    },

    showPrevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    showPage(page) {
      this.currentPage = page;
    },
  },

  components: {
    Trap
  },
}
</script>