export interface IState {
	title: string,
	pages: IPage[],
	allPages: IPage[],
	externalLinks: IPage[],
	count: number,
	templateMap: Object,
	rekai: boolean,
	ids: string[],
	isSitePage: boolean
}

export interface IType {
	title: string,
	class: string,
}

export interface IPage {
	id: string,
	title: string,
	url: string,
	type: IType,
}

const deafultState: IState = {
	title: '',
	pages: [],
	allPages: [],
	externalLinks: [],
	count: 0,
	templateMap: {},
	rekai: false,
	ids: [],
	isSitePage: false
}

export default deafultState;
