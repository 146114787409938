import { MutationTree, Mutation } from 'vuex';
import { IState, IPage } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const setPages: MutationHandler<IPage[]> = (state, { payload }) => {
	state.pages = payload;
}

const setAllPages: MutationHandler<IPage[]> = (state, { payload }) => {
	state.allPages = payload;
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	setPages: setPages,
	setAllPages: setAllPages
}

export default mutations
