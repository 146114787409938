<template>
	<div class="esw-related-links" v-bind:class="{ 'esw-related-links--startpage' : isSitePage}" v-if="pages.length > 0">
		<div class="esw-related-links__heading">
			<h2 class="esw-related-links__heading--text">{{title}}</h2>
		</div>
		<ul class="esw-related-links__list">
			<li class="esw-related-links__list--item" v-for="link in pages" :key="link.id">
				<a class="esw-related-links__list--item__link" :href="link.url">
					{{link.title}}
					<span v-if="link.type && !isSitePage" class="esw-related-links__list--item__link--type" :class="link.type.class ? link.type.class : ''">{{link.type.title}}</span>
				</a>
			</li>
		</ul>
		<button v-if="count < allPages.length"
			class="esw-related-links__list--show-popover"
			aria-haspopup="true"
    		@click="showPopover = true">
			<span
				class="esw-related-links__list--show-popover__icon rs_skip"
				aria-hidden="true">
			</span>
			<span class="esw-related-links__list--show-popover__text">
				Visa fler
			</span>
  	</button>
		<div v-if="showPopover">
    	<pop-over
        @closePopover="showPopover = false"
        :items="allPages"
      />
  	</div>
	</div>
</template>

<script>
import PopOver from './components/PopOver.vue';

export default {
	name: 'app',
	data() {
		return {
      showPopover: false,
		}
	},
	computed: {
		title(){
			return this.$store.state.title;
		},
		allPages(){
			return this.$store.state.allPages;
		},
		pages() {
			return this.allPages.slice(0, this.count);
		},
		internalPages() {
			return this.$store.state.pages;
		},
		rekai(){
			return this.$store.state.rekai;
		},
		templateMap(){
			return this.$store.state.templateMap;
		},
		count(){
			return this.$store.state.count;
		},
		ids(){
			return this.$store.state.ids;
		},
		external(){
			return this.$store.state.externalLinks;
		},
		isSitePage(){
			return this.$store.state.isSitePage;
		}
	},
	methods: {
		getPages(){
			var pages = this.internalPages ? this.internalPages : [];
			var external = this.external ? this.external : [];

			var templates = this.templateMap;
			if(this.rekai && this.ids.length > 0){
				// Object that will be sent to backend
				var reranking = {
					operation: 'rank',
					list: [{label: 'Relaterade länkar', objects: this.ids}]
				};
				var options = {
					customer: window.__rekai.customer,
					operations: [reranking]
				}
				// Do prediction
				window.__rekai.predict(options, 
					function(data) {
						var predictionsList = data.operations[0].list;
						for(var i = 0; i < predictionsList.length; i++){
							var predictionObject = predictionsList[i];
							var predictions = predictionObject.predictions;
							var label = predictionObject.label;
							if(label == 'Relaterade länkar'){
								if(predictions.length > 0){
									pages = predictions.map((link) => {
										return {
											id: link.pageid,
											title: link.title,
											url: link.ldjson ? link.ldjson[0].url : link.url,
											type: templates[link.ldjson[0]['@type']] || { class:"", title: ""}
										}
									});
									pages = pages.concat(external);
									this.$store.commit({
										type: 'setPages',
										payload: pages,
									})
									
								}
							}
						}
					}.bind(this)
				);
			} else {
				pages = pages.concat(external);
				this.$store.commit({
					type: 'setAllPages',
					payload: pages,
				})
			}
		},
	},
	mounted(){
		this.getPages();
	},
	components: {
		PopOver
	}
}
</script>
